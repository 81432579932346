import React from 'react';
import SEO from "../components/seo"
import Header from '../components/Header/Header';
import Banner from '../components/Home/Banner/HomeBanner';
import Loadable from "@loadable/component"

const Footer = Loadable(() => import('../components/Footer/Footer'))
const About = Loadable(() => import('../components/Home/About/About'))
const StaticContent = Loadable(() => import('../components/StaticContent/StaticContent'))
const StaticContentSidebar = Loadable(() => import("../components/StaticContent/StaticContentSidebar"))
const BookValuation = Loadable(() => import('../components/Home/BookValuation/BookValuation'))
const FormBlock = Loadable(() => import('../components/Home/FormBlock/FormBlock'))
const ServiceContentOne = Loadable(() => import('../components/ServiceContentBlock/ServiceContentOne'))
const ServiceContentTwo = Loadable(() => import('../components/ServiceContentBlock/ServiceContentTwo'))
const CountBlock = Loadable(() => import('../components/Home/CountBlock/CountBlock'))
const GlobalCount = Loadable(() => import('../components/Home/CountBlock/GlobalCount'))
const GetKnow = Loadable(() => import('../components/Home/GetKnow/GetKnow'))
const OurNetwork = Loadable(() => import('../components/Home/OurNetwork/OurNetwork'))
const OurNetworkIndividual = Loadable(() => import('../components/Home/OurNetwork/OurNetworkIndividual'))
const CaseStudySection = Loadable(() => import("../components/CaseStudies/LatestCaseStudies"))
const WhyUse = Loadable(() => import('../components/WhyUse/WhyUse'))
const Benefits = Loadable(() => import('../components/WhyUse/Benefits'))
const Disclaimer = Loadable(() => import("../components/Disclaimer/Disclaimer"))

const HomeModules = (props) => {
    return (
        <React.Fragment>
            <SEO title={props.page.Meta_Title} description={props.page.Meta_Description} image={props.page.Banner_Image ? props.page.Banner_Image.url : ''} />
            <div className={props.page.Layout === 'Homepage' ? "home-header" : props.page.Layout === 'Default' ? 'wrapper area-guide-wrapper' : props.page.Layout === 'Staticpage' || props.page.Layout === 'Background_Image_Layout' ? 'wrapper static-page' : ''}>
                <Header />

                {props.page.Layout === 'Homepage' && props.page.Banner_Image && props.page.Choose_Menu[0].URL === 'home' &&
                    <Banner {...props.page} />
                }

                {props.page.Layout === 'Homepage' && props.page.Banner_Image && props.page.Choose_Menu[0].URL !== 'home' &&
                    <Banner {...props.page} />
                }
            </div>

            {
                props.modules && props.modules.map((module, i) => {
                    return (
                        <>
                            {module.Intro_Content &&
                                <About {...module} />
                            }

                            {module.Add_Service_Tile &&
                                <GetKnow {...module} />
                            }

                            {module.Add_Static_Content &&
                                <>
                                    {module.Style == 'With_Sidebar' ?
                                        <StaticContentSidebar {...module} {...props.page} count={i} /> : <StaticContent {...module} style={module.Style} count={i} />
                                    }
                                </>
                            }

                            {module.Select_Module && module.Select_Module.Help_CTA_1_Link == null &&
                                <FormBlock {...module.Select_Module} count={i} />
                            }

                            {module.Select_Module && module.Select_Module.Help_CTA_1_Link &&
                                <BookValuation {...module.Select_Module} />
                            }

                            {/**Service content block*/}
                            {module.Add_New_Block &&
                                <>
                                    {module.Add_New_Block.length === 2 ?
                                        <ServiceContentTwo {...module} num="0" class="only-two" /> :
                                        <>
                                            {module.Add_New_Block && module.Add_New_Block.slice(0, 1).map((service, i) => {
                                                return (
                                                    <ServiceContentOne {...service} />
                                                )
                                            })}
                                            {module.Add_New_Block.length > 1 &&
                                                <ServiceContentTwo {...module} num="1" />
                                            }
                                        </>
                                    }
                                </>
                            }

                            {/**Statistics block*/}
                            {module.Add_Stats &&
                                <CountBlock {...module} />
                            }
                            {module.Stats_Title && <GlobalCount {...module} />}

                            {/**Our Network */}
                            {module.Choose_Module === "Our_Network" &&
                                <OurNetwork />
                            }
                            {module.type === "GLSTRAPI_ComponentComponentsAddOurNetwork" &&
                                <OurNetworkIndividual {...module} />
                            }
                            {/**Why Arun Estates */}
                            {module.Choose_Module === "Why_Arun_Estates" &&
                                <WhyUse />
                            }

                            {/**Case Studies */}
                            {module.Select_Case_Studies_to_Show &&
                                <CaseStudySection {...module} />
                            }

                            {module.Add_Benefit &&
                                <Benefits  {...module} />
                            }
                            {module.Add_Disclaimer_Content &&
                                <Disclaimer {...module}/>
                            }

                        </>
                    )
                })
            }
            <Footer />
        </React.Fragment>
    )
}
export default HomeModules;
